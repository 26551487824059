$(function() {
    let $modelSlider = $('.js-model-slider');

    $modelSlider.slick({
        arrows: false,
        autoplay: true,
        dots: true,
        slidesToShow: 1,
        dotsClass: 'model__slider-dots'
    });
});