$(function() {
    let audiMap;
    ymaps.ready(function() {
        audiMap = new ymaps.Map('map', {
            center: [55.90820965015344,37.5982041925033],
            zoom: 16,
        });
        audiPlacemark = new ymaps.Placemark(audiMap.getCenter(), {
            hintContent: 'Собственный значок метки',
            balloonContent: 'Это красивая метка'
        }, {
            iconLayout: 'default#image',
            iconImageHref: './img/content/pin.png',
            iconImageSize: [36, 40],
            iconImageOffset: [-10, -30]
        }),
        
        audiMap.geoObjects
        .add(audiPlacemark);
        audiMap.behaviors.disable('drag');
        
    });
    
});