$(function() {
    $(".js-anchor").on("click","a", function (event) {

        event.preventDefault();

        var id  = $(this).attr('href'),

            top = $(id).offset().top;

        $('body,html').animate({scrollTop: top}, 1000);

    });

    let $anchorLinks = $('.js-anhor__links'),
        $window = $(window),
        $anchorLinksOffset,
        isFixed = false;
    $window.on('load resize', function() {
        $anchorLinksOffset = $anchorLinks.offset().top;
        let $anchorLinksH = $anchorLinks.height();
        $anchorLinks.parent().height($anchorLinksH);
    });
    $window.on('load scroll', function() {
        let $windowTop = $window.scrollTop();
        if($windowTop > $anchorLinksOffset ) {
            if(!isFixed) {
                $anchorLinks.addClass('anchor__links_fidex');
                isFixed = true;
            }
            
        } else {
            if(isFixed) {
                $anchorLinks.removeClass('anchor__links_fidex');
                isFixed = false;
            }
        }
    });
});