let video = document.querySelector(".js-video");
let isMove = false;

window.addEventListener('load', function() {
    if(!isMove) {
        setTimeout(function() {
            video.play();
            isMove = true;
        }, 500);
    }
});