$(function () {
    $.fn.setCursorPosition = function (pos) {
        if ($(this).get(0).setSelectionRange) {
            $(this).get(0).setSelectionRange(pos, pos);
        } else if ($(this).get(0).createTextRange) {
            var range = $(this).get(0).createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
        }
    };
    $('input[name="phone"]').mask("+7 (999) 999-9999").click(function () {
        $(this).setCursorPosition(4);
    });


    initForm();

    function initForm() {
        $('body').on('submit', 'form', function (e) {
            var t = $(this);
            if (!isFormValidate(t)) {
                e.preventDefault();
                e.stopPropagation();
                $('.has-error', t).first().focus();

            } else if (t.hasClass('ajax-form')) {
                e.preventDefault();
                t.closest('.ajax-form');
                t.addClass('load');

                sendFormAjax(t, function (data) {
                    formSendResult(t, data);
                    t.removeClass('load');
                });
            }
        });

        $('body').on('change', '.has-error', function () {
            var t = $(this);

            if (isFieldValidate(t)) {
                t.closest('.has-error').removeClass('has-error');
            }
        });
    }

    /**
     * Валидация E-mail адреса
     * @param {string} emailAddress - e-mail для проверки
     * @returns {Boolean}
     */
    function isValidEmail(emailAddress) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(emailAddress);
    }

    /**
     * Валидация всей формы
     * @param {object} form jQuery объект формы
     * @param {string} error_class класс ошибки
     * @returns {Boolean}
     */
    function isFormValidate(form, error_class) {
        var result = true,
            rq = $('.required', form).length,
            check = [
                'input[type="text"]',
                'input[type="login"]',
                'input[type="password"]',
                'input[type="number"]',
                'input[type="checkbox"]',
                'input[type="tel"]',
                'input[type="email"]',
                'input[type="textarea"]',
                'input[type="select"]',
                'textarea',
                'select'
            ],
            parent;

        error_class = error_class || 'has-error';

        $('.required, input, textarea, select').removeClass(error_class);

        if (rq < 1) {
            return result;
        }

        for (var i = 0; i < rq; i++) {
            parent = $('.required', form).eq(i);

            $(check.join(','), parent).each(function () {
                if (!isFieldValidate($(this), error_class)) {
                    return result = false;
                }
            });
        }

        return result;
    }

    /**
     * Проверка валидации поля
     * @param {object} field jQuery объект поля формы
     * @param {string} error_class класс ошибки
     * @returns {Boolean}
     */
    function isFieldValidate(field, error_class) {
        var result = true;

        if (notNull(field) && notNull(field.attr('name')) && field.attr('name') !== '') {
            var val = (field.val() + '').trim();
            if (field.hasClass('valid_email') && !isValidEmail(val)) {
                result = false;
            } else if (field.attr('type') === 'checkbox' && !field.is(':checked')) {
                result = false;
            } else if (!notNull(val) || val === '' || val === field.data('mask')) {
                field.val('');
                result = false;
            }
        }

        if (!result) {
            field.addClass(error_class);
        } else {
            field.removeClass(error_class);
        }

        return result;
    }

    /**
     * Проверяем значение на null и undefined
     * @param {mixed} val значение
     * @returns {Boolean}
     */
    function notNull(val) {
        return val !== null && val !== undefined;
    }

    /**
     * Отправляем форму ajax
     * @param {object} form jQuery объект формы
     * @param {function} callback функция обратного вызова
     */
    function sendFormAjax(form, callback) {
        sendAjax(form.attr('action') || '/', form.serialize(), callback);
    }

    /**
     * Отправляем ajax запрос
     * @param {string} url ссылка
     * @param {object} data данные
     * @param {function} callback функция обратного вызова
     */
    function sendAjax(url, data, callback) {
        callback = callback || function () {
        };

        $.ajax({
            url: url,
            dataType: 'json',
            type: 'POST',
            data: data,
            success: function (data) {
                callback(data);
            },
            error: function (data) {
                callback({
                    'type': 'error',
                    'class': 'danger',
                    'text': data['responseText']
                });
            }
        });
    }

    /**
     * Обработка отправки формы
     * @param {object} form jQuery объект формы
     * @param {object} data данные полученные от сервера
     */
    function formSendResult(form, data) {

        form.after('<div class="form__text"><p>Мы скоро свяжемся с Вами!</p><p>Ваша заявка успешно отправлена!<p></div>');
        form.remove();

        //цель отправка формы
        reachGoal(form.data('goal'), '');
    }

    /**
     * Достижение цели
     * @param {string} goal название цели
     * @param {string} added дополнительная информация
     */
    function reachGoal(goal, added) {
        reachGoalYM(goal, added);
        reachGoalGA(goal);
    }

    /**
     * Отправка цели в Метрику
     * @param {string} goal название цели
     * @param {string} added дополнительная информация
     */
    function reachGoalYM(goal, added) {
        if (window.yaCounter000) {
            window.yaCounter000.reachGoal(goal, added || {});
        }
    }

    /**
     * Отправка цели в Аналитику
     * @param {string} goal название цели
     */
    function reachGoalGA(goal) {
        if (window.gtag) {
            gtag('event', 'event_name', {'event_category': goal});
        }
    }

});
